import { ClientTypes } from "../types";
import { ClientProcess } from "../../api/process";

export const searchMeasurementsAction = (clientId: number) => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: ClientTypes.GET_MEASUREMENTS_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const clientProcess = new ClientProcess();
        const response = await clientProcess.searchMeasurements(clientId);

        return dispatch({
            type: ClientTypes.GET_MEASUREMENTS_SUCCESS,
            payload: {
                clientId: clientId,
                measurements: response,
                loading: false,
                success: true,
            },
        });
    } catch (error) {
        return dispatch({
            type: ClientTypes.GET_MEASUREMENTS_FAILURE,
            payload: {
                loading: false,
                success: false,
            },
        });
    }
};

import { ClientRepository } from "../repositories";
export class ClientProcess {
    private clientRepository: ClientRepository;

    constructor() {
        this.clientRepository = new ClientRepository();
    }

    async searchMeasurements(clientId: number): Promise<any> {
        const response = await this.clientRepository.searchMeasurements(clientId);
        return response.data;
    }

}

import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../../configurations";


export class ClientRepository {
    private lmBootApi2: AxiosInstance;

    constructor() {
        this.lmBootApi2 = fetcher(process.env.REACT_APP_URL_API_2 as string);
    }

    searchMeasurements(clientId: number): Promise<AxiosResponse<any>> {
        return this.lmBootApi2.get<any>(`/api/cliente/search-measurements/${clientId}`);
    }

}


import { ClientStore } from './../../interfaces/client.interface';
import { AnyAction } from "redux";
import { ClientTypes } from "../types";

const initialState: ClientStore = {
    clientId: "",
    measurements: null,
    loading: false,
    success: true
};

export const client = (
    state = initialState,
    action: { type: string; payload: ClientStore } | AnyAction,
): ClientStore => {
    const { loading, success, clientId, measurements, selectedMeasurement} = action.payload || {};
    switch (action.type) {
        case ClientTypes.GET_MEASUREMENTS_LOADING:
            return {
                ...state,
                loading,
            };
        case ClientTypes.GET_MEASUREMENTS_SUCCESS:
            return {
                ...state,
                clientId,
                measurements,
                loading,
                success,
            };
        case ClientTypes.GET_MEASUREMENTS_FAILURE:
            return {
                ...state,
                loading,
                success,
            };
        default:
            return state;
    }
};
